<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加喷涂配件</en-button>
    </template>
    <flex-box>
      <template #default="{ height }">
        <div class="flex">
          <div>
            <en-tree
              :data="tree.treeData"
              :props="{ label: 'message', children: 'children' }"
              style="width: 240px; overflow-y: auto"
              @current-change="tree.current.change"
              highlight-current
              ref="mainTree"
              :loading="tree.loading"
              node-key="id"
              :expand-on-click-node="false"
            ></en-tree>
          </div>
          <div>
            <form-query :method="table.get">
              <en-form-item label="编码">
                <en-input v-model="form.data.serialNo"></en-input>
              </en-form-item>
              <en-form-item label="名称">
                <en-input v-model="form.data.name"></en-input>
              </en-form-item>
              <en-form-item label="品牌">
                <en-input v-model="form.data.brand"></en-input>
              </en-form-item>
              <en-form-item label="型号">
                <en-input v-model="form.data.model"></en-input>
              </en-form-item>
              <en-form-item label="条码">
                <en-input v-model="form.data.barcode"></en-input>
              </en-form-item>
              <en-form-item label="适用车型">
                <en-input v-model="form.data.primaryVehicleSpec"></en-input>
              </en-form-item>
              <en-form-item label="通用车型">
                <en-input v-model="form.data.secondaryVehicleSpecs"></en-input>
              </en-form-item>
              <en-form-item label="产地">
                <en-input v-model="form.data.placeOfProduction"></en-input>
              </en-form-item>
              <en-form-item label="备注">
                <en-input v-model="form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
              </en-form-item>
            </form-query>

            <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
              <en-table-column label="操作" width="300">
                <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayGoodsDto'] }">
                  <en-button @click="table.operation.warehouse.click(row)" type="primary" text>仓库</en-button>
                  <en-button
                    v-if="
                      store.user.accessRights.find((item) => item === 'PRICE_PURCHASE') ||
                      store.user.accessRights.find((item) => item === 'PRICE_SALE') ||
                      store.user.accessRights.find((item) => item === 'PRICE_SERVICE')
                    "
                    @click="table.operation.price.click(row)"
                    type="primary"
                    text
                    >价格</en-button
                  >

                  <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
                </template>
              </en-table-column>
              <en-table-column label="名称" prop="storageGoodsSpecification.goods.name">
                <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayGoodsDto'] }">
                  <en-button type="primary" text @click="table.operation.name.click(row)">{{ row.storageGoodsSpecification?.goods?.name }}</en-button>
                </template>
              </en-table-column>
              <en-table-column label="编码" prop="storageGoodsSpecification.goods.serialNo" width="140"></en-table-column>
              <en-table-column label="基本单位" prop="storageGoodsSpecification.goods.warehouseUnit"></en-table-column>
              <en-table-column label="配件类别" prop="type.message"></en-table-column>
              <en-table-column v-if="store.accessRightsHash.PRICE_PURCHASE" label="库存单价" prop="inventoryPrimeCost">
                <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayGoodsDto'] }">
                  <span v-if="store.accessRightsHash.PRICE_PURCHASE">{{ row.inventoryPrimeCost }}</span>
                  <template v-else>--</template>
                </template>
              </en-table-column>
              <en-table-column v-if="store.accessRightsHash.PRICE_PURCHASE" label="运营成本单价">
                <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayGoodsDto'] }">{{ row.primeCost }}</template>
              </en-table-column>
              <en-table-column v-if="store.accessRightsHash.PRICE_SALE" label="盘点单价">
                <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayGoodsDto'] }">{{ row.stockTakingPrice }}</template>
              </en-table-column>
              <en-table-column
                v-if="store.accessRightsHash.PRICE_SALE"
                label="销售单价"
                prop="storageGoodsSpecification.goodsSpecificationAddition.salePrice1"
              >
                <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayGoodsDto'] }">{{
                  row.storageGoodsSpecification?.goodsSpecificationAddition.salePrice1
                }}</template>
              </en-table-column>
              <en-table-column label="品牌" prop="storageGoodsSpecification.goods.brand"></en-table-column>
              <en-table-column label="型号" prop="storageGoodsSpecification.goods.model"></en-table-column>
              <en-table-column label="条码" prop="storageGoodsSpecification.goods.barcode"></en-table-column>
              <en-table-column label="适用车型" prop="storageGoodsSpecification.goods.primaryVehicleSpec">
                <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayGoodsDto'] }">{{
                  row.storageGoodsSpecification?.goods?.primaryVehicleSpec.join(',')
                }}</template>
              </en-table-column>
              <en-table-column label="产地" prop="storageGoodsSpecification.goods.placeOfProduction"></en-table-column>
              <en-table-column label="备注" prop="storageGoodsSpecification.goods.comment"></en-table-column>
            </en-table>
          </div>
        </div>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="warehouse.visible" :title="'仓库'" size="80%" direction="btt">
    <flex-box>
      <template #default="{ height }">
        <en-table
          :data="warehouse.table.tableData"
          :height="height"
          :loading="warehouse.table.loading"
          :method="warehouse.table.get"
          pagination
          :paging="warehouse.table.paging"
        >
          <en-table-column label="仓库名称" prop="warehouse.name"></en-table-column>

          <en-table-column label="上限" prop="upperLimit">
            <template #default="{ row }: { row: warehouseInitializer }">
              <en-input v-model="row.upperLimit"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="警戒线" prop="alarmLine">
            <template #default="{ row }: { row: warehouseInitializer }">
              <en-input v-model="row.alarmLine"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="下限" prop="lowerLimit">
            <template #default="{ row }: { row: warehouseInitializer }">
              <en-input v-model="row.lowerLimit"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="备注" prop="comment">
            <template #default="{ row }: { row: warehouseInitializer }">
              <en-input v-model="row.comment"></en-input>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
    <template #footer>
      <en-button @click="warehouse.footer.cancel.click">取消</en-button>
      <button-ajax :method="warehouse.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
  <en-drawer v-model="price.visible" :title="'价格'" size="80%" direction="btt">
    <flex-box>
      <template #default="{ height }">
        <en-table
          :data="price.form.data.specifications"
          :height="height"
          :loading="warehouse.table.loading"
          :method="warehouse.table.get"
          pagination
          :paging="warehouse.table.paging"
        >
          <en-table-column label="规格" prop="name"></en-table-column>
          <en-table-column label="换算比例" prop="weight"></en-table-column>
          <en-table-column label="维修价格" prop="servicePrice" v-if="store.user.accessRights.find((item) => item === 'PRICE_SERVICE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.servicePrice"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="进价一" prop="purchasePrice1" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.purchasePrice1"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="进价二" prop="purchasePrice2" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.purchasePrice2"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="进价三" prop="purchasePrice3" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.purchasePrice3"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="销价一" prop="salePrice1" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.salePrice1"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="销价二" prop="salePrice2" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.salePrice2"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="销价三" prop="salePrice3" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.salePrice3"></en-input>
            </template>
          </en-table-column>

          <en-table-column label="最低售价" prop="salePriceLowest" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-input v-model="row.salePriceLowest"></en-input>
            </template>
          </en-table-column>
          <en-table-column label="默认维修规格" prop="defaultService" v-if="store.user.accessRights.find((item) => item === 'PRICE_SERVICE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-radio @input="price.table.service.click(row)" label="Y" :model-value="row.defaultService?.code">是</en-radio>
            </template>
          </en-table-column>
          <en-table-column label="默认采购规格" prop="defaultPurchase" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-radio @input="price.table.purchase.click(row)" label="Y" :model-value="row.defaultPurchase?.code">是</en-radio>
            </template>
          </en-table-column>
          <en-table-column label="默认销售规格" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-radio @input="price.table.sale.click(row)" label="Y" :model-value="row.defaultSale?.code">是</en-radio>
            </template>
          </en-table-column>
          <en-table-column label="默认盘点规格">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-radio @input="price.table.taking.click(row)" label="Y" :model-value="row.defaultStockTaking?.code">是</en-radio>
            </template>
          </en-table-column>
          <en-table-column label="默认移库规格">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
              <en-radio @input="price.table.transfer.click(row)" label="Y" :model-value="row.defaultStockTransfer?.code">是</en-radio>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
    <template #footer>
      <en-button @click="price.footer.cancel.click">取消</en-button>
      <button-ajax :method="price.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑喷涂配件' : '添加喷涂配件'" size="40%">
    <en-tabs v-model="tabs.active">
      <en-tab-pane label="基本信息" name="base">
        <en-form
          :model="detail.form.data"
          :rules="detail.form.rules"
          :loading="detail.form.loading"
          :ref="setRef('detailForm')"
          class="grid grid-cols-2 gap-x-6"
        >
          <en-form-item label="配件名称" prop="goods">
            <select-maintain
              v-model="detail.form.data.storageGoodsSpecification.goods"
              :ajax="{
                action: 'GET /enospray/common/goods/enoch',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: '' }"
              value-key="id"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="配件助记符">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.nameHint" disabled></en-input>
          </en-form-item>
          <en-form-item label="编码">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.serialNo" disabled></en-input>
          </en-form-item>
          <en-form-item label="配件类别" prop="category">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.category.name" disabled></en-input>
          </en-form-item>
          <en-form-item label="基本单位">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.warehouseUnit" disabled></en-input>
          </en-form-item>
          <en-form-item label="安装位置">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.location" disabled></en-input>
          </en-form-item>
          <en-form-item label="配件品牌">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.brand" disabled></en-input>
          </en-form-item>
          <en-form-item label="配件型号">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.model" disabled></en-input>
          </en-form-item>
          <en-form-item label="配件状态">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.status.message" disabled></en-input>
          </en-form-item>
          <en-form-item label="是否计算前置库存">
            <en-input v-model="detail.form.data.storageGoodsSpecification.goods.calculationPreInventory.message" disabled></en-input>
          </en-form-item>
          <en-form-item label="互换组名称">
            <en-input
              :model-value="detail.form.data.storageGoodsSpecification.goods.tags && detail.form.data.storageGoodsSpecification.goods.tags.name"
              disabled
            ></en-input>
          </en-form-item>
          <en-form-item label="备注">
            <en-input
              v-model="detail.form.data.storageGoodsSpecification.goods.comment"
              disabled
              type="textarea"
              maxlength="200"
              show-word-limit
            ></en-input>
          </en-form-item>
          <en-form-item label="添加时间">
            <en-date-picker
              v-model="detail.form.data.storageGoodsSpecification.goods.preparedDatetime"
              class="w-full"
              value-format="YYYY-MM-DD"
              disabled
            ></en-date-picker>
          </en-form-item>
        </en-form>
      </en-tab-pane>
      <en-tab-pane label="喷涂扩展" name="spread">
        <en-form
          :model="detail.form.data"
          :rules="detail.form.rules"
          :loading="detail.form.loading"
          :ref="setRef('detailForm')"
          class="grid grid-cols-2 gap-x-6"
        >
          <en-form-item label="配件性质">
            <en-input v-model="detail.form.data.type.goodsTypeCategory.message" disabled></en-input>
          </en-form-item>
          <en-form-item label="配件类别" prop="type">
            <select-maintain
              v-model="detail.form.data.type"
              :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['SPRGDSTP']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="仓库规格" prop="storageGoodsSpecification">
            <select-maintain
              v-model="detail.form.data.storageGoodsSpecification.id"
              :options="detail.form.data.storageGoodsSpecification.goods.specifications"
              :props="{ label: 'name', value: 'id' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="调漆间规格" prop="workshopGoodsSpecification">
            <select-maintain
              :disabled="detail.form.data.type.goodsTypeCategory.materialRequisitionMethod.code !== 'STF'"
              :options="detail.form.data.storageGoodsSpecification.goods.specifications"
              :props="{ label: 'name', value: '' }"
              v-model="detail.form.data.workshopGoodsSpecification"
              value-key="id"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="运营成本" prop="primeCost">
            <en-input v-model="detail.form.data.primeCost"></en-input>
          </en-form-item>
          <en-form-item label="盘点单价">
            <en-input v-model="detail.form.data.stockTakingPrice"></en-input>
          </en-form-item>
          <en-form-item label="盘点计算比例">
            <select-maintain
              v-model="detail.form.data.included"
              :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="最低库存量">
            <en-input-number v-model="detail.form.data.lowerLimit" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item label="补货增量值">
            <en-input-number v-model="detail.form.data.replenishCount" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item label="喷涂供应商">
            <select-maintain
              v-model="detail.form.data.spraySupplier"
              :ajax="{
                action: 'GET /enocloud/common/customer',
                params: (params, value) => (params.payload = { name: value, type: 'B', status: 'A', rsexp: 'id,name', pagingEnabled: false })
              }"
              :props="{ label: 'name', value: '' }"
              value-key="id"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="自动盘盈上限">
            <en-input-number :model-value="detail.form.data.autoCheckCountLimit" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item label="调漆间库存算为用量">
            <select-maintain
              v-model="detail.form.data.workshopInventoryUsed"
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params) => (params.paths = ['FLAG'])
              }"
              :props="{ label: 'message', value: '' }"
              value-key="code"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="颜色效果">
            <select-maintain
              v-model="detail.form.data.colorEffect"
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params, value) => (params.paths = ['SPRGDCRET'])
              }"
              :props="{ label: 'message', value: '' }"
              value-key="code"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </en-form>
      </en-tab-pane>
      <en-tab-pane label="单位换算" name="unit">
        <en-table :data="detail.form.data.storageGoodsSpecification.goods.specifications">
          <en-table-column label="规格" prop="name"></en-table-column>
          <en-table-column label="换算比例" prop="weight"></en-table-column
        ></en-table>
      </en-tab-pane>
      <en-tab-pane label="最低库存量" name="low">
        <en-table :data="detail.form.data.lowerInventories">
          <en-table-column label="钣面数起" prop="lowerLimit"></en-table-column>
          <en-table-column label="钣面数止" prop="upperLimit"></en-table-column>
          <en-table-column label="最低库存量" prop="count"></en-table-column
        ></en-table>
      </en-tab-pane>
    </en-tabs>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
interface warehouseInitializer {
  alarmLine: 0
  comment: ''
  lowerLimit: 0
  upperLimit: 0
  warehouseShelf: ''
  warehouse?: object
}
export default factory({
  config: {
    children: {
      tabs: {
        active: 'base'
      },
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tree: {
        ajax: {
          get: {
            action: 'GET /enospray/common/lookup/:lookupType',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = ['SPRGDSTPC']
            }
          }
        },
        computed: {
          treeData() {
            const item = {
              code: '',
              type: 'SPRGDSTPC',
              message: '全部',
              description: ''
            }

            this.tree.data.unshift(item)
            return this.tree.data
          }
        },
        current: {
          change(currentRow: any, currentNode: any) {
            this.form.data.goodsTypeCategoryCode = ''
            this.form.data.goodsTypeCode = ''
            if (currentRow.children) {
              this.form.data.goodsTypeCategoryCode = currentRow.code
            } else {
              this.form.data.goodsTypeCode = currentRow.code
            }
            this.table.get()
          }
        }
      },
      form: {
        is: 'form',
        data: {
          goodsTypeCode: '',
          goodsTypeCategoryCode: '',
          serialNo: '',
          name: '',
          brand: '',
          model: '',
          barcode: '',
          primaryVehicleSpec: '',
          placeOfProduction: '',
          comment: '',
          secondaryVehicleSpecs: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/common/goods',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enospray/common/goods/:goodsId'
          }
        },

        children: {
          operation: {
            warehouse: {
              async click(row: EnosprayCommonDefinitions['SprayGoodsDto']) {
                this.warehouse.form.data.id = row.id
                await this.warehouse.table.get()
                await this.warehouse.form.get()
                this.warehouse.visible = true
              }
            },
            price: {
              async click(row: EnosprayCommonDefinitions['SprayGoodsDto']) {
                this.price.form.data.id = row.storageGoodsSpecification?.goods?.id
                await this.price.form.get()
                this.price.visible = true
              }
            },
            name: {
              async click(row: EnosprayCommonDefinitions['SprayGoodsDto']) {
                this.detail.form.data.id = row.id
                await this.detail.form.get()
                this.detail.visible = true
              }
            },
            delete: {
              async click(row: EnosprayCommonDefinitions['SprayGoodsDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              colorEffect: '',
              storageGoodsSpecification: {
                id: '',
                goods: {
                  nameHint: '',
                  serialNo: '',
                  warehouseUnit: '',
                  supplier: {},
                  importType: {},
                  primaryVehicleSpec: [],
                  location: '',
                  brand: '',
                  model: '',
                  status: {},
                  calculationPreInventory: {},
                  barcode: '',
                  placeOfProduction: '',
                  figureNo: '',
                  tags: [],
                  specifications: [],
                  category: {}
                }
              },
              oems: [],
              primeCost: 0,
              stockTakingPrice: 0,
              lowerLimit: 0,
              replenishCount: 1,
              workshopInventoryUsed: '',
              vehicleSpecs: [],
              workshopGoodsSpecification: {
                goods: {},
                name: ''
              },
              type: {
                goodsTypeCategory: { materialRequisitionMethod: { code: '' }, message: '' },
                code: ''
              },
              lowerInventories: [
                {
                  lowerLimit: 0,
                  upperLimit: 0,
                  count: 0
                }
              ],
              autoCheckCountLimit: 0,
              spraySupplier: ''
            },
            ajax: {
              get: {
                action: 'GET /enospray/common/goods/:goodsId',
                data: 'object',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enospray/common/goods',
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enospray/common/goods',
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入服务顾问组名' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },
      warehouse: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              barcode: '',
              brand: '',
              category: {
                id: '',
                name: ''
              },
              categoryIds: [],
              comment: '',
              figureNo: '',
              id: '',
              importType: {
                code: 'Y',
                type: '',
                message: ''
              },
              inventoryConfigs: [],
              inventoryCount: '',
              model: '',
              name: '',
              nameHint: '',
              placeOfProduction: '',
              preparedBy: {
                id: '',
                name: ''
              },

              primaryVehicleSpec: '',
              secondaryVehicleSpecs: [],
              serialNo: '',
              specifications: [],
              status: {
                code: '',
                type: '',
                message: ''
              },
              tags: [],
              warehouseUnit: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/goods/:goodsId',
                data: 'object',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.warehouse.form.data.id]
                }
              },
              submit: {
                action: 'PUT /enocloud/common/goods/inventory/config',
                params(params) {
                  params.payload = this.warehouse.form.data
                }
              }
            },
            computed: {
              formData() {
                if (!this.warehouse.form) return this.warehouse.form.data
                this.warehouse.form.data.inventoryConfigs.forEach((item) => {
                  const target = this.warehouse.table.tableData.find((inner) => inner.warehouse.id === item.warehouse.id)
                  if (target) {
                    Object.assign(target, item)
                  }
                })
                return this.warehouse.form.data
              }
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/warehouse',
                data: 'array',
                loading: true,
                pagination: true
              }
            },
            computed: {
              tableData() {
                this.warehouse.table.data = this.warehouse.table.data.map((item) => {
                  const res = {
                    alarmLine: 0,
                    comment: '',
                    lowerLimit: 0,
                    upperLimit: 0,
                    warehouseShelf: '',
                    warehouse: {}
                  }
                  res.warehouse = item
                  return res
                })
                return this.warehouse.table.data
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.warehouse.visible = false
              }
            },
            confirm: {
              async click() {
                this.warehouse.form.data.inventoryConfigs = this.warehouse.table.tableData
                await this.warehouse.form.submit()
                return this.table.get().then(() => (this.warehouse.visible = false))
              }
            }
          }
        }
      },
      price: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              id: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/goods/:goodsId',
                data: 'object',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.price.form.data.id]
                }
              },
              submit: {
                action: 'PUT /enocloud/common/goods/specification/addition',
                params(params) {
                  params.payload = this.price.form.data
                }
              }
            }
          },
          table: {
            children: {
              service: {
                click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                  this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultService')
                }
              },
              purchase: {
                click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                  this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultPurchase')
                }
              },
              sale: {
                click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                  this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultSale')
                }
              },
              taking: {
                click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                  this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultStockTaking')
                }
              },
              transfer: {
                click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                  this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultStockTransfer')
                }
              }
            },
            additionFormDataSpecificationsDefaultInputCommon(row: EnocloudCommonDefinitions['GoodsSpecificationDto'], type: any) {
              this.price.form.data.specifications?.forEach((item: EnocloudCommonDefinitions['GoodsSpecificationDto']) => {
                item[type] = {
                  code: 'N'
                }
              })
              row[type] = {
                code: 'Y'
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.price.visible = false
              }
            },
            confirm: {
              async click() {
                this.price.form.data.inventoryConfigs = this.warehouse.table.tableData
                await this.price.form.submit()
                return this.table.get().then(() => (this.price.visible = false))
              }
            }
          }
        }
      }
    }
  },

  async mounted() {
    this.table.get()
    await this.tree.get()
    this.detail.form.get()
  }
})
</script>

<style scoped></style>
